import React from 'react';

import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  TextField,
} from '@mui/material';

function Payment(props) {
  const data = props;
  console.log(data);
  //   console.log(props.name);
  return (
    <Container sx={{ width: '90vw' }}>
      <Grid
        container
        spacing={3}
        sx={{ backgroundColor: 'white', height: '90vh' }}
      >
        {/* Left Box */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            style={{ height: '70vh', textAlign: 'center', padding: '50px' }}
          >
            <Typography variant='h4'>Title</Typography>
            <Typography variant='body1'>
              This is the description of the left box.
            </Typography>
          </Paper>
        </Grid>

        {/* Right Box */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            style={{
              height: '70vh',
              textAlign: 'center',
              padding: '50px',
              marginRight: '30px',
            }}
          >
            {/* Add your form component here */}
            <Box flex={1}>
              <form>
                <TextField
                  label='Input 1'
                  variant='outlined'
                  fullWidth
                  margin='normal'
                />
                <TextField
                  label='Input 2'
                  variant='outlined'
                  fullWidth
                  margin='normal'
                />
                <TextField
                  label='Input 3'
                  variant='outlined'
                  fullWidth
                  margin='normal'
                />
                <TextField
                  label='CVC Stripe Input'
                  variant='outlined'
                  fullWidth
                  margin='normal'
                />
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  size='large'
                >
                  Pay
                </Button>
              </form>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Payment;
