import React from 'react';
import BannerBackground from '../../Assets/home-banner-background.png';
import BannerImage from '../../Assets/Daco_1966903.png';
import About from '../About/About';
import Work from '../Work/Work';
import Testimonial from '../Testimonial/Testimonial';
import Contact from '../Contact/Contact';
import { FiArrowRight } from 'react-icons/fi';

const Home = () => {
  return (
    <>
      <div className='home-container'>
        <div className='home-banner-container'>
          <div className='home-bannerImage-container'>
            {/* <img src={BannerBackground} alt='' /> */}
          </div>
          <div className='home-text-section'>
            <h1 className='primary-heading'>Your Cloud Game Service </h1>
            <p className='primary-text'>
              Cloud gaming, also referred as on-demand gaming or gaming as a
              service (GaaS), enables gamers to access high-quality games on any
              device with a stable internet connection for a subscription fee.
            </p>
            <button className='secondary-button'>
              Subscribe Now <FiArrowRight />{' '}
            </button>
          </div>
          <div className='home-image-section'>
            <img
              src={BannerImage}
              alt=''
              style={{ width: '350px', marginLeft: '100px' }}
            />
          </div>
        </div>
      </div>
      <About />
      <Work />
      <Testimonial />
      <Contact />
    </>
  );
};

export default Home;
