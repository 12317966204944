import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Login from '../../pages/login/LoginForm';
import About from '../../pages/About/About';
import Home from '../../pages/Home/Home';
import Work from '../../pages/Work/Work';
import Contact from '../../pages/Contact/Contact';
import Testimonial from '../../pages/Testimonial/Testimonial';
import Profile from '../../pages/Profile/Profile';
import RegisterForm from '../../pages/login/RegisterForm';
import PageNotFound from '../../pages/notFound/PageNotFound';
import MainDash from '../Dashboard/MainDash/MainDash';
import RightSide from '../Dashboard/RigtSide/RightSide';
import Navbar from '../global/Navbar';
import Footer from '../global/Footer';
import ClientsList from '../../pages/UsersList/ClientsList';
import Sidebar from '../Dashboard/Sidebar';
import PackagesList from '../../pages/PackagesList/PackagesList';
import CardForm from '../../pages/Payment/Payment';
import Success from '../../pages/success/success';
import Guard from '../Guard/Guard';

// import ProfileTest from "../../Pages/profileTest/ProfileTest";
const AppRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route
          path='/'
          element={
            <div className='App'>
              <Navbar />
              <Home />
              <Footer />
            </div>
          }
        />
        <Route
          path='/about'
          element={
            <div className='App'>
              <Navbar />
              <About />
              <Footer />
            </div>
          }
        />
        <Route
          path='/dashboard'
          element={
            <>
              <div className='app'>
                <div className='AppGlass'>
                  <Guard>
                    <Sidebar />
                    <MainDash />
                    <RightSide />
                  </Guard>
                </div>
              </div>
            </>
          }
        />
        <Route
          path='/dashboard/clients'
          element={
            <>
              <div className='app'>
                <div className='AppGlass'>
                  <Sidebar />
                  <ClientsList />
                  {/* <RightSide /> */}
                </div>
              </div>
            </>
          }
        />
        <Route
          path='/dashboard/packages'
          element={
            <>
              <div className='app'>
                <div className='AppGlass'>
                  <Sidebar />
                  <PackagesList />
                  {/* <RightSide /> */}
                </div>
              </div>
            </>
          }
        />
        {/* <Route path="/parent/child1" component={<MainDash/>} /> */}
        <Route
          path='/packages'
          element={
            <div className='App'>
              <Navbar />
              <Work />
              <Footer />
            </div>
          }
        />
        <Route
          path='/success'
          element={
            <div className='App'>
              <Navbar />
              <Success />
              {/* <Footer /> */}
            </div>
          }
        />
        <Route
          path='/cardForm'
          element={
            <div className='App'>
              <Navbar />
              <CardForm />
              <Footer />
            </div>
          }
        />
        <Route
          path='/contact'
          element={
            <div className='App'>
              <Navbar />
              <Contact />
              <Footer />
            </div>
          }
        />
        <Route
          path='/testimonial'
          element={
            <div className='App'>
              <Navbar />
              <Testimonial />
              <Footer />
            </div>
          }
        />
        <Route
          path='/profile'
          element={
            <div className='App'>
              <Navbar />
              <Profile />
              <Footer />
            </div>
          }
        />
        <Route
          path='/login'
          element={
            <div className='App'>
              <Navbar />
              <Login />
              <Footer />
            </div>
          }
        />
        <Route
          path='/register'
          element={
            <div className='App'>
              <Navbar />
              <RegisterForm />
              <Footer />
            </div>
          }
        />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </AnimatePresence>
  );
};
export default AppRoutes;
