/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import Logo from '../../Assets/smallIcon_2.png';
import { BsCart2 } from 'react-icons/bs';
import { HiOutlineBars3 } from 'react-icons/hi2';
import { Box, Button } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import { Link, useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import LoginIcon from '@mui/icons-material/Login';
import { axiosInstance } from '../../config/axios';
import { toast } from 'react-toastify';

const Navbar = () => {
  const navigate = useNavigate();
  const isLoggedIn = true;
  const token = localStorage.getItem('token');
  const handleClick = (event) => {
    navigate('/profile');
  };
  const handleLogin = () => {
    navigate('/login');
  };
  const logout = () => {
    localStorage.clear();
    toast.success('See You Later!!');
    navigate('/');
  };
  const [openMenu, setOpenMenu] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    axiosInstance.get('/user/profile').then((response) => {
      const data = response.data.data.role;
      setUserRole(data);
    });
  }, []);
  const menuOptions = [
    {
      text: 'Home',
      icon: <HomeIcon />,
      link: '/',
    },
    {
      text: 'About',
      icon: <InfoIcon />,
      link: '/about',
    },
    {
      text: 'Testimonials',
      icon: <CommentRoundedIcon />,
      link: '/testimonial',
    },
    {
      text: 'Packages',
      icon: <FeaturedPlayListIcon />,
      link: '/packages',
    },

    {
      text: 'Contact',
      icon: <PhoneRoundedIcon />,
      link: '/contact',
    },
    {
      text: 'Login',
      icon: <LoginIcon />,
      link: '/login',
    },
    // {
    //   text: 'Profile',
    //   icon: <AccountCircleIcon />,
    //   link: '/profile',
    // },
    // {
    //   text: 'Logout',
    //   icon: <LogoutIcon />,
    //   link: '/',
    // },
  ];
  return (
    <nav>
      <div className='nav-logo-container'>
        <img src={Logo} alt='' style={{ width: '100px' }} />
      </div>
      <div className='navbar-links-container'>
        <Link
          to='/'
          style={{
            mr: '3rem',
            textDecoration: 'none',
            color: '#70d8bd',
            fontSize: '1.1rem',
            fontWeight: 600,
          }}
        >
          Home
        </Link>
        <Link
          to='/about'
          style={{
            mr: '3rem',
            textDecoration: 'none',
            color: '#70d8bd',
            fontSize: '1.1rem',
            fontWeight: 600,
          }}
        >
          About
        </Link>
        <Link
          to='/testimonial'
          style={{
            mr: '3rem',
            textDecoration: 'none',
            color: '#70d8bd',
            fontSize: '1.1rem',
            fontWeight: 600,
          }}
        >
          Testimonials
        </Link>
        <Link
          to='/packages'
          style={{
            mr: '3rem',
            textDecoration: 'none',
            color: '#70d8bd',
            fontSize: '1.1rem',
            fontWeight: 600,
          }}
        >
          Packages
        </Link>
        <Link
          to='/contact'
          style={{
            mr: '3rem',
            textDecoration: 'none',
            color: '#70d8bd',
            fontSize: '1.1rem',
            fontWeight: 600,
          }}
        >
          Contact
        </Link>
        {isLoggedIn && (userRole === 'admin' || userRole === 'root') && (
          <Link
            to='/dashboard'
            style={{
              mr: '3rem',
              textDecoration: 'none',
              color: '#70d8bd',
              fontSize: '1.1rem',
              fontWeight: 600,
            }}
          >
            Dashboard
          </Link>
        )}

        {/* <a href=''>
          <BsCart2 className='navbar-cart-icon' />
        </a> */}
        {token && (
          <Button
            id='basic-button'
            aria-controls='basic-menu'
            aria-haspopup='true'
            aria-expanded='true'
            onClick={handleClick}
            disableRipple
            title='profile'
          >
            <AccountCircleIcon
              sx={{
                fontSize: {
                  lg: '40px',
                  xs: '25px',
                  color: '#70d8bd',
                },
                '&:hover': {
                  color: '#70d8bd',
                },
              }}
            />
          </Button>
        )}

        {token && (
          <Button
            id='basic-button'
            aria-controls='basic-menu'
            aria-haspopup='true'
            aria-expanded='true'
            onClick={logout}
            disableRipple
            title='logout'
          >
            <LogoutIcon
              sx={{
                fontSize: {
                  lg: '40px',
                  xs: '25px',
                  color: '#70d8bd',
                },
                '&:hover': {
                  color: '#70d8bd',
                },
              }}
            />
          </Button>
        )}

        {!token && (
          <button className='primary-button' onClick={handleLogin}>
            Login Now
          </button>
        )}
      </div>
      <div className='navbar-menu-container'>
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor='right'>
        <Box
          sx={{ width: 250, backgroundColor: '#70d8bd', height: '100%' }}
          role='presentation'
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton
                  key={item.link}
                  disableRipple
                  onClick={() => navigate(item.link)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
  );
};

export default Navbar;
