import React from 'react';
import { axiosInstance } from '../../config/axios';
import { toast } from 'react-toastify';

function SinglePackage(props) {
  const handleSubscribeClick = (id) => {
    const packageId = { id };
    console.log(packageId);
    axiosInstance
      .post('/payment/create', id)
      .then((response) => {
        window.location.href = response.data.data.url;
      })
      .catch((error) => {
        toast.error('You have to login firsr');
        window.location.href = '/login';
      });
  };
  return (
    <div className='work-section-info'>
      <h2>{props.i.name}</h2>
      <p>{props.i.description}</p>
      <p>Price : {props.i.price}</p>
      <p>Duration : {props.i.duration}</p>

      <button
        className='secondary-button'
        // onClick={handleSubscribeClick}
        onClick={() => handleSubscribeClick({ packageId: props.i.id })}
      >
        Subscribe
      </button>
    </div>
  );
}

export default SinglePackage;
