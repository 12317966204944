import React, { useState } from 'react';
import HttpsIcon from '@mui/icons-material/Https';
import IconButton from '@mui/joy/IconButton';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Input from '@mui/joy/Input';
import {
  Box,
  FormHelperText,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import './LoginForm.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

export default function RegisterForm() {
  const [passwordType, setPasswordType] = useState(true);
  const navigate = useNavigate();
  const toggleClass = () => {
    navigate('/login');
  };
  const register = useFormik({
    initialValues: {
      f_name: '',
      l_name: '',
      email: '',
      password: '',
      password_confirmation: '',
    },
    validationSchema: yup.object({
      f_name: yup.string().required('First name is Required'),
      l_name: yup.string().required('Last name is Required'),
      email: yup
        .string()
        .email('*Should be a valid email')
        .required('Required'),
      password: yup.string().required('Required'),
      password_confirmation: yup
        .string()
        .oneOf([yup.ref('password'), null, 'Passwords must match'])
        .required('Confirm Password is required*'),
    }),
    onSubmit: (values) => {
      axios
        .post(
          'https://vartola.net/cloud_dashboard_service/public/api/register',
          values
        )
        .then((res) => {
          toast.success(res.data.message);
          navigate('/login');
        })
        .catch((e) => {
          toast.error('Something wrong happened while logining');
        });
    },
  });
  return (
    <Box className='login'>
      <Box className='login_box'>
        <Box className='left'>
          <Box className='contact'>
            <form onSubmit={register.handleSubmit}>
              <h3>Create new account</h3>
              <input
                type='text'
                name='f_name'
                onChange={register.handleChange}
                value={register.values.f_name}
                placeholder='FIRST NAME'
                onBlur={register.handleBlur}
              />
              {register.errors.f_name && register.touched.f_name && (
                <FormHelperText sx={{ color: 'red' }} id='f_name'>
                  {register.errors.f_name}
                </FormHelperText>
              )}
              <input
                type='text'
                name='l_name'
                onChange={register.handleChange}
                value={register.values.l_name}
                placeholder='LAST NAME'
                onBlur={register.handleBlur}
              />
              {register.errors.l_name && register.touched.l_name && (
                <FormHelperText sx={{ color: 'red' }} id='last_name'>
                  {register.errors.l_name}
                </FormHelperText>
              )}
              <input
                type='text'
                name='email'
                onChange={register.handleChange}
                value={register.values.email}
                placeholder='EMAIL'
                onBlur={register.handleBlur}
              />
              {register.errors.email && register.touched.email && (
                <FormHelperText sx={{ color: 'red' }} id='email'>
                  {register.errors.email}
                </FormHelperText>
              )}
              {/* Password */}
              <Input
                startDecorator={
                  <HttpsIcon
                    sx={{
                      color: '#141b2d',
                    }}
                  />
                }
                placeholder='Password'
                type={passwordType ? 'password' : 'text'}
                endDecorator={
                  <IconButton color='neutral' size='sm'>
                    {passwordType ? (
                      <VisibilityRoundedIcon
                        sx={{
                          color: '#141b2d',
                        }}
                        onClick={() => setPasswordType(false)}
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={{
                          color: '#141b2d',
                        }}
                        onClick={() => setPasswordType(true)}
                      />
                    )}
                  </IconButton>
                }
                variant='soft'
                name='password'
                id='password'
                label='Password'
                onBlur={register.handleBlur}
                onChange={register.handleChange}
              />

              {register.errors.password && register.touched.password && (
                <Typography
                  fontWeight={'bold'}
                  fontSize={13}
                  variant='p'
                  color='red'
                >
                  {register.errors.password}
                </Typography>
              )}

              {/*Confirm Password */}
              <Input
                startDecorator={<HttpsIcon />}
                placeholder={'Confirm Password'}
                type={passwordType ? 'password' : 'text'}
                endDecorator={
                  <IconButton color='neutral' size='sm'>
                    {passwordType ? (
                      <VisibilityRoundedIcon
                        sx={{
                          color: '#141b2d',
                        }}
                        onClick={() => setPasswordType(false)}
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={{
                          color: '#141b2d',
                        }}
                        onClick={() => setPasswordType(true)}
                      />
                    )}
                  </IconButton>
                }
                sx={{
                  mt: '15px',
                }}
                name='password_confirmation'
                id='password_confirmation'
                label={'confirm Password'}
                onBlur={register.handleBlur}
                onChange={register.handleChange}
              />

              {register.errors.password_confirmation &&
                register.touched.password_confirmation && (
                  <Typography
                    fontWeight={'bold'}
                    fontSize={13}
                    variant='p'
                    color='red'
                  >
                    {register.errors.password_confirmation}
                  </Typography>
                )}
              <a onClick={toggleClass}>Already have account </a>
              <button className='submit' type='submit'>
                Submit
              </button>
            </form>
          </Box>
        </Box>
        <Box className='right'>
          <Box className='right-text'>
            <h2>Create New Account </h2>
            <h5>Welcome to your control panal </h5>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
