import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { axiosInstance } from '../../config/axios';
import { useState } from 'react';
import { toast } from 'react-toastify';

export default function AddNewPackage({ isFormOpened, handleCloseDialog }) {
  const AddPackage = async (newMatch) => {
    try {
      await axiosInstance
        .post('/plans', newMatch)
        .then((response) => {
          handleCloseDialog();
          toast.success(response.data.message);
          console.log(response);
          //   fetchData();
          // console.log(response);
        })
        .catch((error) => {
          toast.error('Something wrong happened while creating package');
        });
    } catch (error) {
      console.error('Error uploading file: ', error);
    }
  };

  const initialValues = {
    name: '',
    desc: '',
    price: 0,
    duration: '',
  };
  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    desc: Yup.string().required('Required'),
    price: Yup.number().required('Required'),
    duration: Yup.string().required('Required'),
  });
  const handleSubmit = (values) => {
    const NewPackage = {
      name: values.name,
      desc: values.desc,
      price: values.price,
      duration: values.duration,
    };
    AddPackage(NewPackage);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const {
    handleSubmit: handleFormSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
    resetForm,
  } = formik;

  // const [open, setOpen] = React.useState(false);

  return (
    <Box component='form' onSubmit={formik.handleSubmit}>
      <Dialog open={isFormOpened}>
        <DialogTitle
          style={{
            fontSize: '25px',
            fontWeight: 'bold',

            textAlign: 'center',
          }}
        >
          New Package
        </DialogTitle>
        <form onSubmit={handleFormSubmit}>
          <DialogContent>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                autoFocus
                margin='dense'
                id='name'
                label='Package Name'
                type='text'
                fullWidth
                variant='standard'
                name='name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && errors.name}
                helperText={touched.name && errors.name}
              />
              <TextField
                sx={{ mt: '30px' }}
                id='text'
                label='Package Description'
                multiline
                type='text'
                fullWidth
                variant='standard'
                name='desc'
                value={values.desc}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.desc && errors.desc}
                helperText={touched.desc && errors.desc}
              />
              <TextField
                sx={{ mt: '30px' }}
                id='text'
                label='Package Price'
                multiline
                type='number'
                fullWidth
                variant='standard'
                name='price'
                value={values.price}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.price && errors.price}
                helperText={touched.price && errors.price}
              />
              <TextField
                sx={{ mt: '30px' }}
                id='text'
                label='Package Duration'
                multiline
                type='text'
                fullWidth
                variant='standard'
                name='duration'
                value={values.duration}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.duration && errors.duration}
                helperText={touched.duration && errors.duration}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button type='submit' disabled={isSubmitting}>
              Add
            </Button>
            <Button onClick={handleCloseDialog}>Cancel</Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}
