import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';

function Success() {
  return (
    <Box
      sx={{
        height: '90vh',
        textAlign: 'center',
        padding: '40px 0px',
        backgroundColor: '#ebf0f5',
      }}
    >
      <Box
        sx={{
          background: 'white',
          padding: '60px',
          borderRadius: '4px',
          boxShadow: '0 2px 3px #c8d0d8',
          display: 'inline-block',
          margin: '200px auto',
        }}
      >
        <Box
          sx={{
            borderRadius: '200px',
            height: '200px',
            width: '200px',
            background: '#f8faf5',
            margin: '0 auto',
          }}
        >
          <i
            className='checkmark'
            style={{
              color: '#9abc66',
              fontSize: '200px',
              lineHeight: '200px',
              ml: '-15px',
            }}
          >
            ✓
          </i>
        </Box>
        <Typography
          variant='h1'
          sx={{
            color: '#88b04b',

            fontWeight: 900,
            fontSize: '40px',
            mb: '10px',
          }}
        >
          Success
        </Typography>
        <Typography
          variant='p'
          sx={{
            color: '#404f5e',

            fontSize: '20px',
            margin: 0,
          }}
        >
          We received your checkout request;
          <br />
          we'll be in touch shortly!
        </Typography>
      </Box>
    </Box>
  );
}

export default Success;
