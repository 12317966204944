import React, { useState } from 'react';
import './Sidebar.css';
import Logo from './imgs/logo.png';
import { UilSignOutAlt } from '@iconscout/react-unicons';
import { SidebarData } from './Data/Data';
import { UilBars } from '@iconscout/react-unicons';
import { motion } from 'framer-motion';
import { Link, useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import ListItemButton from '@mui/material/ListItemButton';

const Sidebar = () => {
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();
  const [expanded, setExpaned] = useState(true);

  const sidebarVariants = {
    true: {
      left: '0',
    },
    false: {
      left: '-60%',
    },
  };
  // console.log(window.innerWidth);
  return (
    <>
      <div
        className='bars'
        style={expanded ? { left: '60%' } : { left: '5%' }}
        onClick={() => setExpaned(!expanded)}
      >
        <UilBars />
      </div>
      <motion.div
        className='sidebar'
        variants={sidebarVariants}
        animate={window.innerWidth <= 768 ? `${expanded}` : ''}
      >
        {/* logo */}
        <div className='logo'>
          <img src={Logo} alt='logo' />
          <span>
            Cl<span>o</span>ud
          </span>
        </div>

        <div className='menu'>
          {SidebarData.map((item, index) => {
            return (
              <div
                className={selected === index ? 'menuItem active' : 'menuItem'}
                key={index}
                onClick={() => setSelected(index)}
              >
                <ListItemButton
                  key={item.link}
                  disableRipple
                  onClick={() => navigate(item.link)}
                >
                  <item.icon />
                  <span>{item.heading}</span>
                </ListItemButton>
              </div>
            );
          })}
          {/* signoutIcon */}
          <div className='menuItem'>
            <Link to='/'>
              <UilSignOutAlt />
            </Link>
            Go to website
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar;
