import React, { useEffect, useState } from 'react';
import { axiosInstance } from '../../config/axios';
import { Box, Button, TextField } from '@mui/material';
import Header from '../../Components/Dashboard/Header';
import { UilClipboardAlt } from '@iconscout/react-unicons';
import { Delete, Edit } from '@mui/icons-material';
import AddNewPackage from './NewPackage';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import UpdatePackage from './UpdatePackage';

function PackagesList() {
  const [packages, setPackages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleUpdate = (props) => {
    setOpen(!open);
  };
  const deletePackage = (props) => {
    axiosInstance
      .delete(`/plans?id=${props}`)
      .then((response) => {
        console.log(response);
        toast.success(response.data.message);
      })
      .catch((error) => {
        toast.error('Error happened while deleting the package');
      });
  };
  const formik = useFormik({
    initialValues: {
      // Initialize form fields with the fetched data
      // Example:
      name: '',
      desc: '',
      price: 0,
      duration: '',
    },
    onSubmit: (values, { resetForm }) => {
      // Send a PUT request to update the data
      axiosInstance
        .patch('/plans', values)
        .then((response) => {
          toast.success(response.data.message);
          // console.log('Data updated successfully:', response.data);

          // Update the items array with the updated data

          setPackages(values);
          resetForm(); // Reset the form after successful update
        })
        .catch((error) => {
          toast.error('Error updating data');
          console.error('Error updating data:', error);
        });
    },
  });
  useEffect(() => {
    axiosInstance.get('/plans/all').then((response) => {
      console.log(response.data.data.data);
      setPackages(response.data.data.data);
      // setAllMatches(response.data.data.data);
    });
  }, []);
  return (
    <Box m='100px'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Header
          title='Packages'
          subtitle='List of All Packages for Future Reference'
        />
        <AddNewPackage
          isFormOpened={isOpen}
          handleCloseDialog={() => setIsOpen(false)}
        />
        <Button
          sx={{
            backgroundColor: '#141b2d',
            color: 'white',
            fontSize: '15px',
            padding: '10px 20px',
            '&:hover': {
              bgcolor: '#141b2d',
              color: 'white',
            },
          }}
          onClick={() => handleOpen()}
        >
          <UilClipboardAlt sx={{ ml: '100px' }} />
          Add New Package
        </Button>
      </Box>

      <div className='work-section-bottom'>
        {packages.map((data) => (
          <div className='work-section-info' key={data.title}>
            <h2>{data.name}</h2>

            <p>{data.description}</p>

            <p>Price : {data.price}</p>

            <p>Duration : {data.duration}</p>

            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              sx={{ margin: '10px' }}
            >
              <UpdatePackage
                isFormOpened={open}
                handleCloseDialog={() => setOpen(false)}
                props={data}
              />

              <button
                className='secondary-button'
                onClick={() => handleUpdate(data)}
              >
                Edit
              </button>

              <button
                className='secondary-button'
                onClick={() => deletePackage(data.id)}
              >
                <Delete sx={{ ml: '10px' }} />
              </button>
            </Box>

            {/* {!token && <p>Please log in to subscribe.</p>} */}
          </div>
        ))}
      </div>
    </Box>
  );
}

export default PackagesList;
