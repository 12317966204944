import { Typography, Box } from '@mui/material';

const Header = ({ title, subtitle }) => {
  return (
    <Box mb='30px'>
      <Typography
        variant='h4'
        color='#141b2d'
        fontWeight='bold'
        sx={{ m: '0 0 5px 0' }}
      >
        {title}
      </Typography>
      <Typography variant='h5' color='#70d8bd'>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
