import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { axiosInstance } from '../../config/axios';
import { useState } from 'react';
import { toast } from 'react-toastify';

export default function UpdatePackage({
  isFormOpened,
  handleCloseDialog,
  props,
}) {
  const [packages, setPackages] = useState([]);
  console.log(props);
  const formik = useFormik({
    initialValues: {
      // Initialize form fields with the fetched data
      // Example:
      name: props.name,
      desc: props.desc,
      price: props.price,
      duration: props.duration,
    },
    onSubmit: (values, { resetForm }) => {
      // Send a PUT request to update the data
      values = { values: { ...values }, id: props.id };
      axiosInstance
        .patch('/plans', values)
        .then((response) => {
          toast.success(response.data.message);
          // console.log('Data updated successfully:', response.data);

          // Update the items array with the updated data

          setPackages(values);
          resetForm(); // Reset the form after successful update
        })
        .catch((error) => {
          toast.error('Error updating data');
          console.error('Error updating data:', error);
        });
    },
  });
  // const [open, setOpen] = React.useState(false);

  return (
    <Box component='form' onSubmit={formik.handleSubmit}>
      <Dialog open={isFormOpened}>
        <DialogTitle
          style={{
            fontSize: '25px',
            fontWeight: 'bold',

            textAlign: 'center',
          }}
        >
          Update Package
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                type='text'
                required
                fullWidth
                id='name'
                label='Name '
                name='name'
                autoComplete='name'
                defaultValue={props.name}
                onChange={formik.handleChange}
                value={formik.values.name}
                sx={{ margin: '10px' }}
              />
              <TextField
                type='text'
                required
                fullWidth
                id='name'
                label='Description '
                name='desc'
                autoComplete='name'
                defaultValue={props.desc}
                onChange={formik.handleChange}
                value={formik.values.desc}
              />
              <TextField
                type='number'
                required
                fullWidth
                id='name'
                label='Price '
                name='price'
                autoComplete='name'
                defaultValue={props.price}
                onChange={formik.handleChange}
                value={formik.values.price}
                sx={{ margin: '10px' }}
              />
              <TextField
                type='text'
                required
                fullWidth
                id='name'
                label='Duration '
                name='duration'
                autoComplete='name'
                defaultValue={props.duration}
                onChange={formik.handleChange}
                value={formik.values.duration}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button type='submit' disabled={formik.isSubmitting}>
              Update
            </Button>
            <Button onClick={handleCloseDialog}>Cancel</Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}
