import React, { useEffect, useState } from 'react';
import ProfilePic from '../../Assets/john-doe-image.png';
import { axiosInstance } from '../../config/axios';
import { AiFillStar } from 'react-icons/ai';

export default function Profile() {
  const [user, setUser] = useState({});
  useEffect(() => {
    axiosInstance.get('/user/profile').then((response) => {
      const data = response.data.data;
      setUser(data);
      console.log(data);
    });
  }, []);
  return (
    <div className='work-section-wrapper'>
      <div className='work-section-top'>
        <p className='primary-subheading'>Profile</p>
        <h1 className='primary-heading'>Here is your data</h1>
        {/* <p className='primary-text'>
          Lorem ipsum dolor sit amet consectetur. Non tincidunt magna non et
          elit. Dolor turpis molestie dui magnis facilisis at fringilla quam.
        </p> */}
      </div>
      <div className='testimonial-section-bottom'>
        <img src={ProfilePic} alt='' />
        <p>{user.email}</p>
        {/* <div className='testimonials-stars-container'>
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
        </div> */}
        <h2>{user.name}</h2>
      </div>
    </div>
  );
}
