import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Header from '../../Components/Dashboard/Header';
import { useEffect, useState } from 'react';
import { Delete, Edit } from '@mui/icons-material';
// import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../config/axios';

const ClientsList = () => {
  const [users, setAllUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [params, setParams] = useState({});

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleEdit = (params) => {
    setOpenEdit(!openEdit);
    setParams(params);
    console.log(params);
  };
  const handleDelete = (params) => {
    axiosInstance
      .delete(`/user/delete?userId=${params.row.id}`)
      .then((response) => {
        toast.success('User has been deleted successfully');
        fetchData();
      })
      .catch((error) => {
        toast.error('Something wrong happened');
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axiosInstance.get('/user/all').then((response) => {
      console.log(response.data.data.data);
      const updatedData = response.data.data.data.map((item, index) => ({
        id: index + 1, // Generate a unique id for each row
        ...item,
      }));
      setAllUsers(updatedData);
    });
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'Edit',
      headerName: 'Edit User',
      width: 150,
      renderCell: (params) => (
        <>
          <Edit
            style={{ cursor: 'pointer', color: '#666666' }}
            onClick={() => handleEdit(params.row)}
          />
        </>
      ),
    },
    {
      field: 'Delete',
      headerName: 'Delete User',
      width: 150,
      renderCell: (params) => (
        <>
          <Delete
            style={{ cursor: 'pointer', marginRight: 15, color: '#c30010' }}
            onClick={() => handleDelete(params)}
          />
        </>
      ),
    },
  ];

  return (
    <Box m='20px'>
      <Header
        title='Clients'
        subtitle='List of All Clients for Future Reference'
      />
      <Box
        m='40px 0 0 0'
        height='75vh'
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: '#2e7c67',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#3e4396',
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            // backgroundColor: colors.primary[400],
            backgroundColor: 'transparent',
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: '#3e4396',
          },
          '& .MuiCheckbox-root': {
            color: '#b7ebde',
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: '#e0e0e0',
          },
        }}
      >
        <DataGrid
          rows={users}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default ClientsList;
