import React, { useEffect, useState } from 'react';
import { axiosInstance } from '../../config/axios';
import { useNavigate } from 'react-router-dom';
import SinglePackage from './SinglePackage';

const Work = ({ isLoading }) => {
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);

  const packagesList = packages.map((data) => {
    return <SinglePackage i={data} key={data.id} title={data.title} />;
  });

  useEffect(() => {
    axiosInstance.get('/plans/all').then((response) => {
      console.log(response.data.data.data);
      setPackages(response.data.data.data);
      // setAllMatches(response.data.data.data);
    });
  }, []);

  return (
    <div className='work-section-wrapper'>
      <div className='work-section-top'>
        <p className='primary-subheading'>Packages</p>
        <h1 className='primary-heading'>What are the Packages</h1>
        <p className='primary-text'>
          Lorem ipsum dolor sit amet consectetur. Non tincidunt magna non et
          elit. Dolor turpis molestie dui magnis facilisis at fringilla quam.
        </p>
      </div>

      <div className='work-section-bottom'>{packagesList}</div>
    </div>
  );
};

export default Work;
