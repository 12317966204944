import {
  Box,
  FormHelperText,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import './LoginForm.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function LoginForm() {
  const navigate = useNavigate();

  const toggleClass = () => {
    navigate('/register');
  };
  const login = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('*Should be a valid email')
        .required('Required'),
      password: yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      axios
        .post(
          'https://vartola.net/cloud_dashboard_service/public/api/login',
          values
        )
        .then((res) => {
          toast.success(res.data.message);
          localStorage.setItem('token', res.data.user?.authToken);
          navigate('/profile');
        })
        .catch((e) => {
          toast.error('Something wrong happened while logining');
        });
    },
  });

  return (
    <Box className='login'>
      <Box className='login_box'>
        <Box className='left'>
          <Box className='contact'>
            <form onSubmit={login.handleSubmit}>
              <h3>SIGN IN</h3>
              <input
                type='text'
                name='email'
                onChange={login.handleChange}
                value={login.values.email}
                placeholder='EMAIL'
                onBlur={login.handleBlur}
              />
              {login.errors.email && login.touched.email && (
                <FormHelperText sx={{ color: 'red' }} id='email'>
                  {login.errors.email}
                </FormHelperText>
              )}
              <input
                type='text'
                placeholder='PASSWORD'
                name='password'
                onChange={login.handleChange}
                value={login.values.password}
                onBlur={login.handleBlur}
              />
              {login.errors.password && login.touched.password && (
                <FormHelperText sx={{ color: 'red' }} id='email'>
                  {login.errors.password}
                </FormHelperText>
              )}
              <a onClick={toggleClass}>Create new account</a>
              <button className='submit' type='submit'>
                Submit
              </button>
            </form>
          </Box>
        </Box>
        <Box className='right'>
          <Box className='right-text'>
            <h2>Cloud service</h2>
            <h5>Welcome to your control panal </h5>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default LoginForm;
